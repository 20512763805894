import React from "react";
import "./App.css";
import { useAppViewCtrl } from "./container/app-view-state";
import Navbar from "./components/Navbar";
import Movements from "./components/Movements";
import Controls from "./components/Controls";
import ViewOptions from "./components/ViewOptions";
import Viewport from "./viewport";
import Intercom from "react-intercom";

const App = (props) => {
  const { toothClicked, movements } = useAppViewCtrl();
  // if (window.Intercom) {
  //   window.Intercom("update", { hide_default_launcher: true });
  // }
  return (
    <>
      <Viewport
        path={props.match.params.file}
        userid={props.match.params.userid}
        patientid={props.match.params.patientid}
      />
      <Navbar
        name={props.match.params.name}
        userid={props.match.params.userid}
        patientid={props.match.params.patientid}
        version={props.match.params.file}
      />
      <Movements ToothClicked={toothClicked} Movements={movements} />
      <Controls interfaceType={props.match.params.interface_type} />
      <ViewOptions />
      {/* <Intercom appID="mg5n9yyr" /> */}
    </>
  );
};

export default App;
