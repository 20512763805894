import { useEffect } from "react"
import {
  ChatwootBlackPages,
  setChatwootUser,
  hideChatwoot,
  showChatwoot,
} from "../commons/chatwoot"
import { getUser } from "../../User"

export const useChatwoot = (location) => {
  useEffect(() => {
    console.log("useChatwoot useEffect")
    const handleChatwootReady = async () => {
      console.log("handleChatwootReady -- pathname", window.location.pathname)

      const hideChatwootFlag = ChatwootBlackPages.some((page) =>
        window.location.pathname.startsWith(page)
      )
      if (hideChatwootFlag) {
        hideChatwoot()
      } else {
        showChatwoot()
      }

      const user = await getUser()
      if (user && user.attributes && user.attributes.sub && !hideChatwootFlag) {
        setChatwootUser(user.attributes)
      }
    }

    window.addEventListener("chatwoot:ready", handleChatwootReady)

    if (window.$chatwoot && window.$chatwoot.isReady) {
      handleChatwootReady()
    }

    return () => {
      window.removeEventListener("chatwoot:ready", handleChatwootReady)
    }
  }, [location])
}