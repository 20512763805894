import CryptoJS from "crypto-js"

const userIdentityValidationCode = "eiAV4zY3EQL2U3rzyEsok9As"

export const ChatwootBlackPages = ["/desktop", "/threedview"]

const generateIdentifierHash = (userId, secretKey) => {
  const hash = CryptoJS.HmacSHA256(userId, secretKey)
  const hashInHex = hash.toString(CryptoJS.enc.Hex)
  return hashInHex
  // return crypto.createHmac("sha256", secretKey).update(userId).digest("hex")
}

export function setChatwootUser(user) {
  if (window.$chatwoot && window.$chatwoot.setUser) {
    // User is logged in
    // console.log("User is logged in", {
    //   name: user.name,
    //   email: user.email,
    //   identifier_hash: generateIdentifierHash(
    //     user.sub,
    //     userIdentityValidationCode
    //   ),
    // })
    window.$chatwoot.setUser(user.sub, {
      name: user.name,
      email: user.email,
      identifier_hash: generateIdentifierHash(
        user.sub,
        userIdentityValidationCode
      ),
    })
    console.log("chatwoot setUser success")
  } else {
    console.log("window.$chatwoot.setUser is not defined", window.$chatwoot)
  }
}

export function resetChatwoot() {
  if (window.$chatwoot && window.$chatwoot.reset) {
    window.$chatwoot.reset()
  }
}

export function hideChatwoot() {
  if (window.$chatwoot && window.$chatwoot.toggleBubbleVisibility) {
    window.$chatwoot.toggleBubbleVisibility("hide")
  }
}

export function showChatwoot() {
  if (window.$chatwoot && window.$chatwoot.toggleBubbleVisibility) {
    window.$chatwoot.toggleBubbleVisibility("show")
  }
}