import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { registerServiceWorker, unregister } from "./registerServiceWorker";
import { Amplify, Auth } from "aws-amplify";
import { processConfig as process } from "./config/env";
// import "antd/dist/antd.css";

// window.LOG_LEVEL='DEBUG'

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "WebAppAPI",
        endpoint: process.env.WEB_API_ENDPOINT,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
      {
        name: process.env.BACK_API_NAME,
        endpoint: process.env.BACK_API_ENDPOINT,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
        },
      },
    ],
  },
  Auth: {
    region: "us-east-2",
    userPoolId: "us-east-2_bNM3Ho5WZ",
    identityPoolId: "us-east-2:4174969e-0d9e-4380-8d38-99e4f1f97cf7",
    userPoolWebClientId: "663500b1d36judotkr083nilhn",
  },
  Storage: {
    bucket: "archform-patient-data-v1",
    region: "us-east-2",
    identityPoolId: "us-east-2:4174969e-0d9e-4380-8d38-99e4f1f97cf7",
  },
  Cache: {
    itemMaxSize: 4999999,
    capacityInBytes: 5000000,
  },
});

// Amplify.Analytics.configure(
//     {
//         autoSessionRecord: false,
//         AWSPinpoint: {
//             // Amazon Pinpoint App Client ID
//             appId: '0b8bf40136244a21b90c75510c085b41',
//             // Amazon service region
//             region: 'us-east-1',
//             mandatorySignIn: false,
//         }
//     }
// )

async function start() {
  // await Amplify.Auth.signOut();
  // Amplify.Cache.clear();
  ReactDOM.render(<App />, document.getElementById("root"));
  // unregister();
}

start();
