import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import {Amplify, Storage, Cache } from "aws-amplify";
import { BrowserRouter as Router, Redirect } from "react-router-dom";

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    width: "100%",
  },
  toolbar: {
    justifyContent: "space-between",
    ...theme.mixins.toolbar,
  },
  logo: {
    // color: '#fff',
    color: "#161d2d!important",
    letterSpacing: ".2px!important",
    // fontSize: '3em',
    // lineHeight: '72px',
    fontWeight: "500!important",
    fontFamily: "Inter,sans-serif!important",
    textDecoration: "none!important",
    // marginLeft: '10px'
  },
  search: {
    display: "flex",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 3,
      width: "auto",
    },
    flexGrow: 1,
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // position: 'absolute',
  },
  accountButton: {},
  drawercollapse: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

class ArchFormAppBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acctAnchorEl: null,
      logout: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  // changeSearch = event => {
  //     this.setState({ search: event.target.value })
  // }
  handleClick = (event) => {
    this.setState({ acctAnchorEl: event.currentTarget });
  };

  handleClose = (name) => (event) => {
    this.setState({ acctAnchorEl: null });
    if (name === "logout") {
      Cache.clear();
      if (window.$chatwoot && window.$chatwoot.reset) {
        window.$chatwoot.reset()
      }
      this.setState({ logout: true });
    }
  };

  render() {
    const { classes } = this.props;
    const acctAnchorEl = this.state.acctAnchorEl;
    const search = this.props.search;
    const changesearch = this.props.changesearch;

    if (this.state.logout) return <Redirect push to={{ pathname: "/login" }} />;

    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {changesearch ? (
            <Hidden smUp implementation="css">
              <IconButton onClick={this.props.toggledrawer}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          ) : null}
          <Link to="/" className={classes.logo}>
            <Typography variant="h6" className={classes.logo}>
              ArchForm
            </Typography>
          </Link>

          {changesearch ? (
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon color="primary" />
              </div>
              <InputBase
                placeholder="Search…"
                value={search}
                onChange={changesearch}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          ) : null}
          <IconButton
            aria-owns={acctAnchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={classes.accountButton}
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={acctAnchorEl}
            open={Boolean(acctAnchorEl)}
            onClose={this.handleClose("")}
          >
            {/* <MenuItem onClick={this.handleClose('profile')}>Profile</MenuItem> */}
            <MenuItem onClick={this.handleClose("logout")}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

ArchFormAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArchFormAppBar);
