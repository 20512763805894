const preview = {
  BACK_API_NAME: "archform-dashboard-back",
  WEB_API_ENDPOINT: "https://f9it4c6v28.execute-api.us-east-2.amazonaws.com/preview/",
  BACK_API_ENDPOINT: "https://tagxkis576.execute-api.us-east-2.amazonaws.com/prod",
  BACK_API_ENDPOINT_V2: "https://d61bk6a7pl.execute-api.us-east-2.amazonaws.com/Prod",
}

const prod = {
  BACK_API_NAME: "archform-dashboard-back",
  WEB_API_ENDPOINT: "https://d4gi5sijca.execute-api.us-east-2.amazonaws.com/prod/",
  BACK_API_ENDPOINT: "https://tagxkis576.execute-api.us-east-2.amazonaws.com/prod",
  BACK_API_ENDPOINT_V2: "https://d61bk6a7pl.execute-api.us-east-2.amazonaws.com/Prod",
}

export const processConfig = {
  env: process.env.REACT_APP_NODE === "preview" ? preview: prod
}